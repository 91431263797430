export function Progress({ progress, color }) {
  const progVal = Math.max(0, Math.min(1, progress)); // Clamp the value between 0 and 1

  const containerStyles = {
    width: color === "grey" ? "80vw" : "80vw",
    maxWidth: color === "grey" && "500px",
    backgroundColor: "#f3f3f3",
    borderRadius: "4px",
    height: "10px",
  };

  const fillerStyles = {
    height: "100%",
    width: `${progVal * 100}%`,
    backgroundColor: color === "grey" ? "grey" : "#58cc02",
    borderRadius: "inherit",
    transition: "width 0.2s ease-in-out",
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles} />
    </div>
  );
}
