import { useEffect, useState } from "react";
import { Progress } from "./Progress";

export function VideoPlayer({
  signFile,
  word,
  source,
  setVideoReady,
  videoReady,
}) {
  const baseUrl = "https://media.signbsl.com/videos/bsl";
  const timer = 20;
  const interval = 100;

  const [count, setCount] = useState(timer);

  function countdown() {
    setCount((c) => c - 1);
  }

  useEffect(() => {
    const intervalId = setInterval(countdown, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [signFile]);

  useEffect(() => {
    if (count === 0) {
      setVideoReady(true);
    }
  }, [count, setVideoReady]);
  useEffect(() => {
    // Reset countdown and video readiness when signFile changes
    setCount(timer);
    setVideoReady(false);
  }, [signFile, setVideoReady]);

  return (
    <div className="video-player">
      {videoReady ? (
        <video
          src={`${baseUrl}/${!source ? "signstation" : source}/${signFile}.mp4`}
          type="video/mp4"
          muted
          autoPlay
          playsinline
          controls
        />
      ) : (
        <div className="video-loading">{word}</div>
      )}

      <div className="word">
        {videoReady ? (
          word
        ) : (
          <Progress progress={count / timer - 0.1} color="grey" />
        )}
      </div>
    </div>
  );
}
