import { useState } from "react";
import { VideoPlayer } from "./VideoPlayer";
import { dict } from "../dict";
import { Progress } from "./Progress";

export function Quiz({ setQuizActive }) {
  const [videoReady, setVideoReady] = useState(false);
  const [curWord, setCurWord] = useState(0);
  const [prog, setProg] = useState(0);
  const type = "all";
  const themeWords = dict.filter((wordObj) => {
    if (type === "all") {
      return wordObj;
    } else {
      return wordObj.type === type;
    }
  });

  function handleNewWord() {
    if (prog >= 0.99) {
      setQuizActive(false);
    } else {
      const newWord = Math.floor(Math.random() * themeWords.length);
      if (newWord !== curWord && themeWords[newWord].signFile) {
        setCurWord(newWord);
        setProg((p) => p + 0.05);
      } else {
        handleNewWord();
      }
    }
  }

  return (
    <div className="quiz">
      <Progress progress={prog} />
      <VideoPlayer
        setVideoReady={setVideoReady}
        videoReady={videoReady}
        signFile={themeWords[curWord].signFile}
        word={themeWords[curWord].word}
        source={themeWords[curWord].source}
      />
      {videoReady && (
        <button
          className={videoReady ? "btn" : "btn disabled"}
          onClick={handleNewWord}
        >
          {prog < 0.9 ? "NEXT" : "FINISH"}
        </button>
      )}
      <div className="word__link">
        {" "}
        <a
          href={`https://www.signbsl.com/sign/${themeWords[curWord].word}`}
          target="_blank"
          rel="noreferrer"
        >
          "{themeWords[curWord].word}" on signbsl
        </a>
      </div>
    </div>
  );
}
