import { useState } from "react";
import "./App.css";
import { Quiz } from "./components/Quiz";

export default function App() {
  const [quizActive, setQuizActive] = useState(false);

  function onQuizStart() {
    setQuizActive(true);
  }

  return (
    <div className="app">
      <Navbar />
      <main>
        {quizActive ? (
          <Quiz setQuizActive={setQuizActive} />
        ) : (
          <Begin onClick={onQuizStart} />
        )}
      </main>
      <footer></footer>
    </div>
  );
}

function Begin({ onClick }) {
  return (
    <button onClick={onClick} className="btn">
      Start
    </button>
  );
}

function Navbar() {
  return (
    <header>
      <div className="logo">signlingo</div>
    </header>
  );
}


