export const dict = [
  {
    word: "definitely",
    signFile: "101-06-1088",
    type: "",
    part: "",
    source: "signstation",
    definition: "without question and beyond doubt",
  },
  {
    word: "who",
    signFile: "WHO-3",
    type: "questions",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "how are you?",
    signFile: "101-06-76",
    type: "questions, greeings",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "understand",
    signFile: "understand-2",
    type: "",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: 1,
    signFile: "one",
    type: "numbers",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: 2,
    signFile: "two",
    type: "numbers",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: 3,
    signFile: "three",
    type: "numbers",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: 4,
    signFile: "four",
    type: "numbers",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: 5,
    signFile: "five",
    type: "numbers",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: 6,
    signFile: "six",
    type: "numbers",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: 7,
    signFile: "seven",
    type: "numbers",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: 8,
    signFile: "eight",
    type: "numbers",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: 9,
    signFile: "nine",
    type: "numbers",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: 10,
    signFile: "ten",
    type: "numbers",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "good morning",
    signFile: "good%20morning",
    type: "greetings",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "good afternoon",
    signFile: "good-afternoon",
    type: "greetings",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "good evening",
    signFile: "mp4/fsjwqXLEmf8",
    type: "greetings",
    part: "",
    source: "youtube",
    definition: "",
  },
  {
    word: "thank you",
    signFile: "thank you",
    type: "",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "what",
    signFile: "what",
    type: "questions",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "where",
    signFile: "where",
    type: "questions",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "when",
    signFile: "when",
    type: "questions",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "why",
    signFile: "why",
    type: "questions",
    part: "",
    source: "signstation",
    definition:
      "question word; what is the reason (`how come' is a nonstandard variant)",
  },
  {
    word: "how",
    signFile: "how",
    type: "questions",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "want",
    signFile: "want",
    type: "",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "attack",
    signFile: "attack",
    type: "",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "buy",
    signFile: "buy",
    type: "verbs",
    part: "",
    source: "",
    definition:
      "obtain by purchase; acquire by means of a financial transaction",
  },
  {
    word: "kill",
    signFile: "kill",
    type: "verbs",
    part: "",
    source: "signstation",
    definition:
      "cause to die; put to death, usually intentionally or knowingly",
  },
  {
    word: "name",
    signFile: "name",
    type: "",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "happy",
    signFile: "happy",
    type: "emotions",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "sad",
    signFile: "101-06-605",
    type: "emotions",
    part: "",
    source: "signstation",
    definition: "experiencing or showing sorrow or unhappiness",
  },
  {
    word: "excited",
    signFile: "excited",
    type: "emotions",
    part: "",
    source: "signstation",
    definition: "marked by uncontrolled excitement or emotion",
  },
  {
    word: "anxious",
    signFile: "anxious",
    type: "emotions",
    part: "",
    source: "signstation",
    definition: "causing or fraught with or showing anxiety",
  },
  {
    word: "yesterday",
    signFile: "yesterday",
    type: "time",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "tomorrow",
    signFile: "tomorrow",
    type: "time",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "today",
    signFile: "today",
    type: "time",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "before",
    signFile: "mp4/RNNqOjSwDvQ",
    type: "time",
    part: "",
    source: "youtube",
    definition: "",
  },
  {
    word: "now",
    signFile: "now",
    type: "time",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "after",
    signFile: "after",
    type: "time",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "earlier",
    signFile: "earlier",
    type: "time",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "later",
    signFile: "later",
    type: "time",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "morning",
    signFile: "morning",
    type: "time",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "afternoon",
    signFile: "afternoon",
    type: "time",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "evening",
    signFile: "evening",
    type: "time",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "day",
    signFile: "day",
    type: "time",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "night",
    signFile: "night",
    type: "time",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "this morning",
    signFile: "this morning",
    type: "time",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "this afternoon",
    signFile: "this afternoon",
    type: "time",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "this evening",
    signFile: "this evening",
    type: "time",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "monday",
    signFile: "Monday",
    type: "time",
    part: "",
    source: "signstation",
    definition: 0,
  },
  {
    word: "tuesday",
    signFile: "Tuesday",
    type: "time",
    part: "",
    source: "signstation",
    definition: 0,
  },
  {
    word: "wednesday",
    signFile: "Wednesday",
    type: "time",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "thursday",
    signFile: "Thursday",
    type: "time",
    part: "",
    source: "signstation",
    definition: 0,
  },
  {
    word: "friday",
    signFile: "Friday",
    type: "time",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "saturday",
    signFile: "Saturday",
    type: "time",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "sunday",
    signFile: "Sunday",
    type: "time",
    part: "",
    source: "signstation",
    definition: 0,
  },
  {
    word: "weekend",
    signFile: "weekend",
    type: "time",
    part: "",
    source: "signstation",
    definition: "",
  },
  {
    word: "next week",
    signFile: "next week",
    type: "time",
    part: "",
    source: "signstation",
    definition: 0,
  },
  {
    word: "next",
    signFile: "next-2",
    type: "time",
    part: "",
    source: "signstation",
    definition: "",
  },

  // BELOW ARE THE GCSE VOCABULARY WORDS

  {
    word: "accept",
    signFile: "accept",
    type: "Verbs",
    part: "",
    source: "",
    definition: "consent to receive or undertake (something offered)",
  },
  {
    word: "arrive",
    signFile: "arrive",
    type: "Verbs",
    part: "",
    source: "",
    definition: "reach a place at the end of a journey or a stage in a journey",
  },
  {
    word: "ask",
    signFile: "ask",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "say something in order to obtain an answer or some information",
  },
  {
    word: "believe",
    signFile: "believe",
    type: "Verbs",
    part: "",
    source: "",
    definition: "accept that (something) is true, especially without proof",
  },
  {
    word: "born",
    signFile: "born",
    type: "Verbs",
    part: "",
    source: "",
    definition: "brought into existence; created",
  },
  {
    word: "break",
    signFile: "break",
    type: "Verbs",
    part: "",
    source: "",
    definition: "separate into pieces as a result of a blow, shock, or strain",
  },
  {
    word: "bring",
    signFile: "bring",
    type: "Verbs",
    part: "",
    source: "",
    definition: "cause to come to a place or position",
  },
  {
    word: "buy",
    signFile: "buy",
    type: "Verbs",
    part: "",
    source: "",
    definition: "obtain in exchange for payment",
  },
  {
    word: "change",
    signFile: "change",
    type: "Verbs",
    part: "",
    source: "",
    definition: "make or become different",
  },
  {
    word: "come",
    signFile: "come",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "move or travel toward or into a place thought of as near or familiar to the speaker",
  },
  {
    word: "communicate",
    signFile: "communicate",
    type: "Verbs",
    part: "",
    source: "",
    definition: "share or exchange information, news, or ideas",
  },
  {
    word: "continue",
    signFile: "continue",
    type: "Verbs",
    part: "",
    source: "",
    definition: "persist in an activity or process",
  },
  {
    word: "cook",
    signFile: "cook",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "prepare (food, a dish, or a meal) by combining and heating the ingredients in various ways",
  },
  {
    word: "do",
    signFile: "do",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "perform (an action, the precise nature of which is often unspecified)",
  },
  {
    word: "drink",
    signFile: "drink",
    type: "Verbs",
    part: "",
    source: "",
    definition: "take (a liquid) into the mouth and swallow",
  },
  {
    word: "drive",
    signFile: "drive",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "operate and control the direction and speed of a motor vehicle",
  },
  {
    word: "drove",
    signFile: "drove",
    type: "Verbs",
    part: "",
    source: "",
    definition: "past of drive",
  },
  {
    word: "eat",
    signFile: "eat",
    type: "Verbs",
    part: "",
    source: "",
    definition: "put (food) into the mouth and chew and swallow it",
  },
  {
    word: "encourage",
    signFile: "encourage",
    type: "Verbs",
    part: "",
    source: "",
    definition: "give support, confidence, or hope to (someone)",
  },
  {
    word: "enter",
    signFile: "enter",
    type: "Verbs",
    part: "",
    source: "",
    definition: "come or go into (a place)",
  },
  {
    word: "exist",
    signFile: "exist",
    type: "Verbs",
    part: "",
    source: "",
    definition: "have objective reality or being",
  },
  {
    word: "explain",
    signFile: "explain",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "make (an idea, situation, or problem) clear to someone by describing it in more detail or revealing relevant facts or ideas",
  },
  {
    word: "feel",
    signFile: "feel",
    type: "Verbs",
    part: "",
    source: "",
    definition: "experience (an emotion or sensation)",
  },
  {
    word: "find",
    signFile: "find",
    type: "Verbs",
    part: "",
    source: "",
    definition: "discover or perceive by chance or unexpectedly",
  },
  {
    word: "finish",
    signFile: "finish",
    type: "Verbs",
    part: "",
    source: "",
    definition: "bring (a task or activity) to an end; complete",
  },
  {
    word: "fish",
    signFile: "fish",
    type: "Verbs",
    part: "",
    source: "",
    definition: "catch or try to catch fish",
  },
  {
    word: "fix",
    signFile: "fix",
    type: "Verbs",
    part: "",
    source: "",
    definition: "fasten (something) securely in a particular place or position",
  },
  {
    word: "fly",
    signFile: "fly",
    type: "Verbs",
    part: "",
    source: "",
    definition: "travel through the air with wings, as a bird does",
  },
  {
    word: "follow",
    signFile: "follow",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "go or come after (a person or thing proceeding ahead); move or travel behind",
  },
  {
    word: "forget",
    signFile: "forget",
    type: "Verbs",
    part: "",
    source: "",
    definition: "fail to remember",
  },
  {
    word: "get",
    signFile: "get",
    type: "Verbs",
    part: "",
    source: "",
    definition: "come to have or hold (something); receive",
  },
  {
    word: "give",
    signFile: "give",
    type: "Verbs",
    part: "",
    source: "",
    definition: "freely transfer the possession of (something) to (someone)",
  },
  {
    word: "go",
    signFile: "go",
    type: "Verbs",
    part: "",
    source: "",
    definition: "move from one place to another; travel",
  },
  {
    word: "grow up",
    signFile: "grow-up",
    type: "Verbs",
    part: "",
    source: "",
    definition: "to reach adulthood",
  },
  {
    word: "happen",
    signFile: "happen",
    type: "Verbs",
    part: "",
    source: "",
    definition: "occur; take place",
  },
  {
    word: "have",
    signFile: "have",
    type: "Verbs",
    part: "",
    source: "",
    definition: "possess, own, or hold",
  },
  {
    word: "hear",
    signFile: "hear",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "perceive with the ear the sound made by (someone or something)",
  },
  {
    word: "help",
    signFile: "help",
    type: "Verbs",
    part: "",
    source: "",
    definition: "serve someone by giving them something",
  },
  {
    word: "hold",
    signFile: "hold",
    type: "Verbs",
    part: "",
    source: "",
    definition: "grasp, carry, or support with one's arms or hands",
  },
  {
    word: "interpret",
    signFile: "interpret",
    type: "Verbs",
    part: "",
    source: "",
    definition: "explain the meaning of (information, words, or actions)",
  },
  {
    word: "involve",
    signFile: "involve",
    type: "Verbs",
    part: "",
    source: "",
    definition: "include (something) as a necessary part or result",
  },
  {
    word: "join",
    signFile: "join",
    type: "Verbs",
    part: "",
    source: "",
    definition: "cause to be or become connected or united",
  },
  {
    word: "kick",
    signFile: "kick",
    type: "Verbs",
    part: "",
    source: "",
    definition: "strike or propel forcibly with the foot",
  },
  {
    word: "know",
    signFile: "know",
    type: "Verbs",
    part: "",
    source: "",
    definition: "be aware of through observation, inquiry, or information",
  },
  {
    word: "know not",
    signFile: "know-not",
    type: "Verbs",
    part: "",
    source: "",
    definition: "past of not know",
  },
  {
    word: "laugh",
    signFile: "laugh",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "make the spontaneous sounds and movements of the face and body that are the instinctive expressions of lively amusement and sometimes also of derision",
  },
  {
    word: "learn",
    signFile: "learn",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "gain or acquire knowledge of or skill in (something) by study, experience, or being taught",
  },
  {
    word: "leave",
    signFile: "leave",
    type: "Verbs",
    part: "",
    source: "",
    definition: "go away from",
  },
  {
    word: "leave-alone",
    signFile: "leave-alone",
    type: "Verbs",
    part: "",
    source: "",
    definition: "past of leave alone",
  },
  {
    word: "like",
    signFile: "like",
    type: "Verbs",
    part: "",
    source: "",
    definition: "find agreeable, enjoyable, or satisfactory",
  },
  {
    word: "live",
    signFile: "live",
    type: "Verbs",
    part: "",
    source: "",
    definition: "remain alive",
  },
  {
    word: "look",
    signFile: "look",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "direct one's gaze toward someone or something or in a specified direction",
  },
  {
    word: "look after",
    signFile: "look-after",
    type: "Verbs",
    part: "",
    source: "",
    definition: "take care of",
  },
  {
    word: "love",
    signFile: "love",
    type: "Verbs",
    part: "",
    source: "",
    definition: "feel a deep romantic or sexual attachment to (someone)",
  },
  {
    word: "make",
    signFile: "make",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "form (something) by putting parts together or combining substances; create",
  },
  {
    word: "marry",
    signFile: "marry",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "form a legally recognized union with (someone) under the law as a married couple",
  },
  {
    word: "mean",
    signFile: "mean",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "intend to convey, indicate, or refer to (a particular thing or notion); signify",
  },
  {
    word: "meet",
    signFile: "meet",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "come into the presence or company of (someone) by chance or arrangement",
  },
  {
    word: "mix",
    signFile: "mix",
    type: "Verbs",
    part: "",
    source: "",
    definition: "combine or put together to form one substance or mass",
  },
  {
    word: "move",
    signFile: "move",
    type: "Verbs",
    part: "",
    source: "",
    definition: "go in a specified direction or manner; change position",
  },
  {
    word: "negotiate",
    signFile: "negotiate",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "try to reach an agreement or compromise by discussion with others",
  },
  {
    word: "pay",
    signFile: "pay",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "give (someone) money that is due for work done, goods received, or a debt incurred",
  },
  {
    word: "pick",
    signFile: "pick",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "take hold of and remove (a flower, fruit, or vegetable) from where it is growing",
  },
  {
    word: "play",
    signFile: "play",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "engage in activity for enjoyment and recreation rather than a serious or practical purpose",
  },
  {
    word: "progress",
    signFile: "progress",
    type: "Verbs",
    part: "",
    source: "",
    definition: "move forward or onward in space or time",
  },
  {
    word: "put aside",
    signFile: "put-aside",
    type: "Verbs",
    part: "",
    source: "",
    definition: "reserve or save for a particular purpose",
  },
  {
    word: "read",
    signFile: "read",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "look at and comprehend the meaning of (written or printed matter) by interpreting the characters or symbols of which it is composed",
  },
  {
    word: "remember",
    signFile: "remember",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "have in or be able to bring to one's mind an awareness of (someone or something from the past)",
  },
  {
    word: "saw",
    signFile: "saw",
    type: "Verbs",
    part: "",
    source: "",
    definition: "past of see",
  },
  {
    word: "say",
    signFile: "say",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "utter words so as to convey information, an opinion, a feeling, or an intention",
  },
  {
    word: "search",
    signFile: "search",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "try to find something by looking or otherwise seeking carefully and thoroughly",
  },
  {
    word: "see",
    signFile: "see",
    type: "Verbs",
    part: "",
    source: "",
    definition: "perceive with the eyes; discern visually",
  },
  {
    word: "send",
    signFile: "send",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "cause to go or be taken to a particular destination; arrange for the delivery of, especially by mail",
  },
  {
    word: "text",
    signFile: "text",
    type: "Verbs",
    part: "",
    source: "",
    definition: "send a text message to",
  },
  {
    word: "shift",
    signFile: "shift",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "move or cause to move from one place to another, especially over a small distance",
  },
  {
    word: "shock",
    signFile: "shock",
    type: "Verbs",
    part: "",
    source: "",
    definition: "cause (someone) to feel surprised and upset",
  },
  {
    word: "show",
    signFile: "show",
    type: "Verbs",
    part: "",
    source: "",
    definition: "be, allow, or cause to be visible",
  },
  {
    word: "sign",
    signFile: "sign",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "write one's name on (a letter, card, or similar item) to identify oneself as the writer or sender",
  },
  {
    word: "sign chat",
    signFile: "sign-chat",
    type: "Verbs",
    part: "",
    source: "",
    definition: "use sign language to communicate",
  },
  {
    word: "sit",
    signFile: "sit",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "adopt or be in a position in which one's weight is supported by one's buttocks rather than one's feet and one's back is upright",
  },
  {
    word: "sleep",
    signFile: "sleep",
    type: "Verbs",
    part: "",
    source: "",
    definition: "be in a state of sleep",
  },
  {
    word: "smell",
    signFile: "smell",
    type: "Verbs",
    part: "",
    source: "",
    definition: "perceive or detect the odour or scent of (something)",
  },
  {
    word: "speak",
    signFile: "speak",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "say something in order to convey information, an opinion, or a feeling",
  },
  {
    word: "stand",
    signFile: "stand",
    type: "Verbs",
    part: "",
    source: "",
    definition: "have or maintain an upright position, supported by one's feet",
  },
  {
    word: "start",
    signFile: "start",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "begin or be reckoned from a particular point in time or space; come into being",
  },
  {
    word: "stay",
    signFile: "stay",
    type: "Verbs",
    part: "",
    source: "",
    definition: "remain in the same place",
  },
  {
    word: "stop",
    signFile: "stop",
    type: "Verbs",
    part: "",
    source: "",
    definition: "come to an end; cease to happen",
  },
  {
    word: "take",
    signFile: "take",
    type: "Verbs",
    part: "",
    source: "",
    definition: "lay hold of (something) with one's hands; reach for and hold",
  },
  {
    word: "talk",
    signFile: "talk",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "speak in order to give information or express ideas or feelings; converse or communicate by spoken words",
  },
  {
    word: "teach",
    signFile: "teach",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "impart knowledge to or instruct (someone) as to how to do something",
  },
  {
    word: "telephone",
    signFile: "telephone",
    type: "Verbs",
    part: "",
    source: "",
    definition: "speak to or attempt to reach (someone) by telephone",
  },
  {
    word: "tell",
    signFile: "tell",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "communicate information, facts, or news to someone in spoken or written words",
  },
  {
    word: "think",
    signFile: "think",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "have a particular opinion, belief, or idea about someone or something",
  },
  {
    word: "travel",
    signFile: "travel",
    type: "Verbs",
    part: "",
    source: "",
    definition: "make a journey, typically of some length or abroad",
  },
  {
    word: "try",
    signFile: "try",
    type: "Verbs",
    part: "",
    source: "",
    definition: "make an attempt or effort to do something",
  },
  {
    word: "understand",
    signFile: "understand",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "perceive the intended meaning of (words, a language, or speaker)",
  },
  {
    word: "use",
    signFile: "use",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "take, hold, or deploy (something) as a means of accomplishing or achieving something; employ",
  },
  {
    word: "visit",
    signFile: "visit",
    type: "Verbs",
    part: "",
    source: "",
    definition: "go to see and spend time with (someone) socially",
  },
  {
    word: "wait",
    signFile: "wait",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "stay where one is or delay action until a particular time or until something else happens",
  },
  {
    word: "wake up",
    signFile: "wake-up",
    type: "Verbs",
    part: "",
    source: "",
    definition: "stop sleeping; become awake",
  },
  {
    word: "walk",
    signFile: "walk",
    type: "Verbs",
    part: "",
    source: "",
    definition:
      "move at a regular and fairly slow pace by lifting and setting down each foot in turn, never having both feet off the ground at once",
  },
  {
    word: "want",
    signFile: "want",
    type: "Verbs",
    part: "",
    source: "",
    definition: "have a desire to possess or do (something); wish for",
  },
  {
    word: "watch",
    signFile: "watch",
    type: "Verbs",
    part: "",
    source: "",
    definition: "look at or observe attentively over a period of time",
  },
  {
    word: "airplane",
    signFile: "airplane",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a powered flying vehicle with fixed wings and a weight greater than that of the air it displaces",
  },
  {
    word: "airport",
    signFile: "airport",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a complex of runways and buildings for the takeoff, landing, and maintenance of civil aircraft, with facilities for passengers",
  },
  {
    word: "ball",
    signFile: "ball",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a solid or hollow sphere or ovoid, especially one that is kicked, thrown, or hit in a game",
  },
  {
    word: "bed",
    signFile: "bed",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a piece of furniture for sleep or rest, typically a framework with a mattress and coverings",
  },
  {
    word: "bicycle",
    signFile: "bicycle",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a vehicle composed of two wheels held in a frame one behind the other, propelled by pedals and steered with handlebars attached to the front wheel",
  },
  {
    word: "blanket",
    signFile: "blanket",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a large piece of woolen or similar material used as a covering on a bed or elsewhere for warmth",
  },
  {
    word: "boat",
    signFile: "boat",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a small vessel propelled on water by oars, sails, or an engine",
  },
  {
    word: "body",
    signFile: "body",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "the physical structure, including the bones, flesh, and organs, of a person or an animal",
  },
  {
    word: "book",
    signFile: "book",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a written or printed work consisting of pages glued or sewn together along one side and bound in covers",
  },
  {
    word: "broadband",
    signFile: "broadband",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "high-capacity transmission technique using a wide range of frequencies, which enables a large number of messages to be communicated simultaneously",
  },
  {
    word: "bus",
    signFile: "bus",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a large motor vehicle carrying passengers by road, typically one serving the public on a fixed route and for a fare",
  },
  {
    word: "bus pass",
    signFile: "bus-pass",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a ticket allowing unlimited travel on buses over a fixed period",
  },
  {
    word: "cable",
    signFile: "cable",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a thick rope of wire or non-metallic fibers, typically used for construction, mooring ships, and towing vehicles",
  },
  {
    word: "car",
    signFile: "car",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a road vehicle, typically with four wheels, powered by an internal combustion engine and able to carry a small number of people",
  },
  {
    word: "ceiling light",
    signFile: "ceiling-light",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a light fixture that is attached to or recessed into the ceiling",
  },
  {
    word: "chest of drawers",
    signFile: "chest-of-drawers",
    type: "Objects",
    part: "",
    source: "",
    definition: "a piece of furniture with drawers for storing clothes",
  },
  {
    word: "computer",
    signFile: "computer",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "an electronic device for storing and processing data, typically in binary form, according to instructions given to it in a variable program",
  },
  {
    word: "cup",
    signFile: "cup",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a small bowl-shaped container for drinking from, typically having a handle",
  },
  {
    word: "cupboard",
    signFile: "cupboard",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a cabinet or closet, usually with a door and shelves, used for storage",
  },
  {
    word: "duvet",
    signFile: "duvet",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a soft quilt filled with down, feathers, or a synthetic fiber, used instead of an upper sheet and blankets",
  },
  {
    word: "flat",
    signFile: "flat",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a set of rooms comprising a residence, typically on one floor of a building",
  },
  {
    word: "fork",
    signFile: "fork",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a tool with two or more prongs, used for lifting food to the mouth or holding it when cutting",
  },
  {
    word: "google",
    signFile: "google",
    type: "Objects",
    part: "",
    source: "",
    definition: "a popular internet search engine and technology company",
  },
  {
    word: "group",
    signFile: "group",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a number of people or things that are located, gathered, or classed together",
  },
  {
    word: "hair",
    signFile: "hair",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "any of the fine threadlike strands growing from the skin of humans, mammals, and some other animals",
  },
  {
    word: "hairdryer",
    signFile: "hairdryer",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "an electrical device for drying a person's hair by blowing warm air over it",
  },
  {
    word: "hand",
    signFile: "hand",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "the end part of a person's arm beyond the wrist, including the palm, fingers, and thumb",
  },
  {
    word: "helicopter",
    signFile: "helicopter",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a type of aircraft that derives both lift and propulsion from one or more sets of horizontally revolving overhead rotors",
  },
  {
    word: "house",
    signFile: "house",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a building for human habitation, especially one that is lived in by a family or small group of people",
  },
  {
    word: "internet",
    signFile: "internet",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a global computer network providing a variety of information and communication facilities, consisting of interconnected networks using standardized communication protocols",
  },
  {
    word: "kettle",
    signFile: "kettle",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a container or device in which water is boiled, having a lid, spout, and handle; a teakettle",
  },
  {
    word: "kilogram",
    signFile: "kilogram",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "the base unit of mass in the International System of Units (SI), equal to 1000 grams",
  },
  {
    word: "knife",
    signFile: "knife",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "an instrument composed of a blade fixed into a handle, used for cutting or as a weapon",
  },
  {
    word: "lift",
    signFile: "lift",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a platform or compartment housed in a shaft for raising and lowering people or things to different floors or levels",
  },
  {
    word: "lorry",
    signFile: "lorry",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a large, heavy motor vehicle for transporting goods or troops; a truck",
  },
  {
    word: "microwave",
    signFile: "microwave",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "an electromagnetic wave with a wavelength in the range 0.001–0.3 m, shorter than that of a normal radio wave but longer than those of infrared radiation",
  },
  {
    word: "mile",
    signFile: "mile",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a unit of linear measure equal to 5,280 feet, or 1,760 yards (approximately 1.609 kilometers)",
  },
  {
    word: "mobile phone",
    signFile: "mobile-phone",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a telephone with access to a cellular radio system so it can be used over a wide area, without a physical connection to a network",
  },
  {
    word: "mobile telephone",
    signFile: "mobile-telephone",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a telephone with access to a cellular radio system so it can be used over a wide area, without a physical connection to a network",
  },
  {
    word: "money",
    signFile: "money",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a current medium of exchange in the form of coins and banknotes; coins and banknotes collectively",
  },
  {
    word: "moon",
    signFile: "moon",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "the natural satellite of the earth, visible (chiefly at night) by reflected light from the sun",
  },
  {
    word: "motorbike",
    signFile: "motorbike",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a light motorcycle, especially one with an engine capacity of up to 125cc",
  },
  {
    word: "mountain",
    signFile: "mountain",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a large natural elevation of the earth's surface rising abruptly from the surrounding level; a large steep hill",
  },
  {
    word: "penny",
    signFile: "penny",
    type: "Objects",
    part: "",
    source: "",
    definition: "a British coin worth one hundredth of a pound",
  },
  {
    word: "pillow",
    signFile: "pillow",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a rectangular cloth bag stuffed with feathers, foam rubber, or other soft materials, used to support the head when lying down",
  },
  {
    word: "plate",
    signFile: "plate",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a flat dish, typically circular and made of china, from which food is eaten or served",
  },
  {
    word: "pound",
    signFile: "pound",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a unit of weight in general use equal to 16 ounces (avoirdupois) or 0.4536 kilogram",
  },
  {
    word: "railway station",
    signFile: "railway-station",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a place where trains regularly stop to load or unload passengers or goods",
  },
  {
    word: "river",
    signFile: "river",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a large natural stream of water flowing in a channel to the sea, a lake, or another such stream",
  },
  {
    word: "sat nav",
    signFile: "sat-nav",
    type: "Objects",
    part: "",
    source: "",
    definition: "a satellite navigation system; GPS",
  },
  {
    word: "scissors",
    signFile: "scissors",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "an instrument used for cutting cloth, paper, and other material, consisting of two blades laid one on top of the other and fastened in the middle so as to allow them to be opened and closed by a thumb and finger inserted through rings on the end of their handles",
  },
  {
    word: "sea",
    signFile: "sea",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "the expanse of salt water that covers most of the earth's surface and surrounds its landmasses",
  },
  {
    word: "sheet",
    signFile: "sheet",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a large rectangular piece of cotton or other fabric, used on a bed to cover the mattress and as a layer beneath blankets when these are used",
  },
  {
    word: "sky",
    signFile: "sky",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "the region of the atmosphere and outer space seen from the earth",
  },
  {
    word: "spoon",
    signFile: "spoon",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "an implement consisting of a small, shallow oval or round bowl on a long handle, used for eating, stirring, and serving food",
  },
  {
    word: "stairs",
    signFile: "stairs",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a series of steps and landings leading from one level to another, often inside a building",
  },
  {
    word: "star",
    signFile: "star",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a fixed luminous point in the night sky that is a large, remote incandescent body like the sun",
  },
  {
    word: "sun",
    signFile: "sun",
    type: "Objects",
    part: "",
    source: "",
    definition: "the star around which the earth orbits",
  },
  {
    word: "table lamp",
    signFile: "table-lamp",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a lamp that sits on a table and is used for localized illumination",
  },
  {
    word: "taxi",
    signFile: "taxi",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a car licensed to transport passengers in return for payment of a fare",
  },
  {
    word: "television",
    signFile: "television",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a system for transmitting visual images and sound that are reproduced on screens, chiefly used to broadcast programs for entertainment, information, and education",
  },
  {
    word: "ticket",
    signFile: "ticket",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a piece of paper or small card that gives the holder a certain right, especially to enter a place, travel by public transport, or participate in an event",
  },
  {
    word: "toaster",
    signFile: "toaster",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "an electrical device for making toast by exposing slices of bread to radiant heat, thus converting them into toast",
  },
  {
    word: "toothbrush",
    signFile: "toothbrush",
    type: "Objects",
    part: "",
    source: "",
    definition: "a small brush with a long handle, used for cleaning the teeth",
  },
  {
    word: "train",
    signFile: "train",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a series of railroad cars moved as a unit by a locomotive or by integral motors",
  },
  {
    word: "tram",
    signFile: "tram",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a passenger vehicle for public use that runs on tracks laid in the streets",
  },
  {
    word: "tube",
    signFile: "tube",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a long, hollow cylinder of metal, plastic, glass, etc., for holding or transporting something, chiefly liquids or gases",
  },
  {
    word: "uber",
    signFile: "uber",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a popular smartphone app that connects drivers with passengers directly",
  },
  {
    word: "ufo",
    signFile: "ufo",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "an unidentified flying object; a supposed spacecraft of alien origin",
  },
  {
    word: "vacuum cleaner",
    signFile: "vacuum-cleaner",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a device that uses suction to remove dirt and dust from floors, carpets, and other surfaces",
  },
  {
    word: "wallet",
    signFile: "wallet",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a pocket-sized, flat, folding holder for money and plastic cards",
  },
  {
    word: "water",
    signFile: "water",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a colorless, transparent, odorless liquid that forms the seas, lakes, rivers, and rain and is the basis of the fluids of living organisms",
  },
  {
    word: "way",
    signFile: "way",
    type: "Objects",
    part: "",
    source: "",
    definition: "a method, style, or manner of doing something",
  },
  {
    word: "wifi",
    signFile: "wifi",
    type: "Objects",
    part: "",
    source: "",
    definition:
      "a facility allowing smartphones, computers, and other devices to connect to the internet or communicate with one another wirelessly within a particular area",
  },
  {
    word: "billion",
    signFile: "billion",
    type: "Numerals",
    part: "",
    source: "",
    definition: "a thousand million, or 1,000,000,000, in the short scale",
  },
  {
    word: "eight",
    signFile: "eight",
    type: "Numerals",
    part: "",
    source: "",
    definition:
      "equivalent to the product of four and two; one more than seven, or two less than ten; 8",
  },
  {
    word: "eighteen",
    signFile: "eighteen",
    type: "Numerals",
    part: "",
    source: "",
    definition:
      "equivalent to the product of nine and two; ten more than eight; 18",
  },
  {
    word: "eighteenth",
    signFile: "eighteenth",
    type: "Numerals",
    part: "",
    source: "",
    definition: "constituting number eighteen in a sequence; 18th",
  },
  {
    word: "eighth",
    signFile: "eighth",
    type: "Numerals",
    part: "",
    source: "",
    definition: "constituting number eight in a sequence; 8th",
  },
  {
    word: "eighty",
    signFile: "eighty",
    type: "Numerals",
    part: "",
    source: "",
    definition: "ten more than seventy; 80",
  },
  {
    word: "eleven",
    signFile: "eleven",
    type: "Numerals",
    part: "",
    source: "",
    definition:
      "equivalent to the product of five and two; one more than ten, or two less than twelve; 11",
  },
  {
    word: "eleventh",
    signFile: "eleventh",
    type: "Numerals",
    part: "",
    source: "",
    definition: "constituting number eleven in a sequence; 11th",
  },
  {
    word: "fifteen",
    signFile: "fifteen",
    type: "Numerals",
    part: "",
    source: "",
    definition:
      "equivalent to the product of three and five; five more than ten, or five less than twenty; 15",
  },
  {
    word: "fifteenth",
    signFile: "fifteenth",
    type: "Numerals",
    part: "",
    source: "",
    definition: "constituting number fifteen in a sequence; 15th",
  },
  {
    word: "fifth",
    signFile: "fifth",
    type: "Numerals",
    part: "",
    source: "",
    definition: "constituting number five in a sequence; 5th",
  },
  {
    word: "fifty",
    signFile: "fifty",
    type: "Numerals",
    part: "",
    source: "",
    definition: "five times ten; 50",
  },
  {
    word: "first",
    signFile: "first",
    type: "Numerals",
    part: "",
    source: "",
    definition: "coming before all others in time or order; earliest; 1st",
  },
  {
    word: "ordinal",
    signFile: "ordinal",
    type: "Numerals",
    part: "",
    source: "",
    definition:
      "a number denoting the place (usually the position in a series) of an item in relation to all others; for example, 'first', 'second', or 'twenty-third'",
  },
  {
    word: "five",
    signFile: "five",
    type: "Numerals",
    part: "",
    source: "",
    definition:
      "equivalent to the product of two and two, plus one; one more than four, or one less than six; 5",
  },
  {
    word: "forty",
    signFile: "forty",
    type: "Numerals",
    part: "",
    source: "",
    definition: "four times ten; 40",
  },
  {
    word: "four",
    signFile: "four",
    type: "Numerals",
    part: "",
    source: "",
    definition: "equivalent to the sum of two and two; one less than five; 4",
  },
  {
    word: "fourteen",
    signFile: "fourteen",
    type: "Numerals",
    part: "",
    source: "",
    definition:
      "equivalent to the product of two and seven; ten more than four, or four less than twenty; 14",
  },
  {
    word: "fourteenth",
    signFile: "fourteenth",
    type: "Numerals",
    part: "",
    source: "",
    definition: "constituting number fourteen in a sequence; 14th",
  },
  {
    word: "fourth",
    signFile: "fourth",
    type: "Numerals",
    part: "",
    source: "",
    definition: "constituting number four in a sequence; 4th",
  },
  {
    word: "hundred",
    signFile: "hundred",
    type: "Numerals",
    part: "",
    source: "",
    definition: "ten times ten; 100",
  },
  {
    word: "hundredth",
    signFile: "hundredth",
    type: "Numerals",
    part: "",
    source: "",
    definition: "constituting number one hundred in a sequence; 100th",
  },
  {
    word: "million",
    signFile: "million",
    type: "Numerals",
    part: "",
    source: "",
    definition: "a thousand thousand, or 1,000,000, in the short scale",
  },
  {
    word: "nine",
    signFile: "nine",
    type: "Numerals",
    part: "",
    source: "",
    definition:
      "equivalent to the sum of three and six; one more than eight, or one less than ten; 9",
  },
  {
    word: "nineteen",
    signFile: "nineteen",
    type: "Numerals",
    part: "",
    source: "",
    definition:
      "equivalent to the sum of ten and nine; one more than eighteen, or one less than twenty; 19",
  },
  {
    word: "nineteenth",
    signFile: "nineteenth",
    type: "Numerals",
    part: "",
    source: "",
    definition: "constituting number nineteen in a sequence; 19th",
  },
  {
    word: "ninety",
    signFile: "ninety",
    type: "Numerals",
    part: "",
    source: "",
    definition: "ten times nine; 90",
  },
  {
    word: "ninth",
    signFile: "ninth",
    type: "Numerals",
    part: "",
    source: "",
    definition: "constituting number nine in a sequence; 9th",
  },
  {
    word: "one",
    signFile: "one",
    type: "Numerals",
    part: "",
    source: "",
    definition: "the lowest cardinal number; one more than zero; 1",
  },
  {
    word: "second",
    signFile: "second",
    type: "Numerals",
    part: "",
    source: "",
    definition:
      "coming after the first and before all others in time or order; 2nd",
  },
  {
    word: "seven",
    signFile: "seven",
    type: "Numerals",
    part: "",
    source: "",
    definition:
      "equivalent to the sum of three and four; one more than six, or one less than eight; 7",
  },
  {
    word: "seventeen",
    signFile: "seventeen",
    type: "Numerals",
    part: "",
    source: "",
    definition:
      "equivalent to the sum of ten and seven; one more than sixteen, or one less than eighteen; 17",
  },
  {
    word: "seventeenth",
    signFile: "seventeenth",
    type: "Numerals",
    part: "",
    source: "",
    definition: "constituting number seventeen in a sequence; 17th",
  },
  {
    word: "seventh",
    signFile: "seventh",
    type: "Numerals",
    part: "",
    source: "",
    definition: "constituting number seven in a sequence; 7th",
  },
  {
    word: "seventy",
    signFile: "seventy",
    type: "Numerals",
    part: "",
    source: "",
    definition: "ten times seven; 70",
  },
  {
    word: "six",
    signFile: "six",
    type: "Numerals",
    part: "",
    source: "",
    definition:
      "equivalent to the sum of five and one; one more than five, or one less than seven; 6",
  },
  {
    word: "sixteen",
    signFile: "sixteen",
    type: "Numerals",
    part: "",
    source: "",
    definition:
      "equivalent to the sum of ten and six; one more than fifteen, or one less than seventeen; 16",
  },
  {
    word: "sixteenth",
    signFile: "sixteenth",
    type: "Numerals",
    part: "",
    source: "",
    definition: "constituting number sixteen in a sequence; 16th",
  },
  {
    word: "sixth",
    signFile: "sixth",
    type: "Numerals",
    part: "",
    source: "",
    definition: "constituting number six in a sequence; 6th",
  },
  {
    word: "sixty",
    signFile: "sixty",
    type: "Numerals",
    part: "",
    source: "",
    definition: "ten times six; 60",
  },
  {
    word: "ten",
    signFile: "ten",
    type: "Numerals",
    part: "",
    source: "",
    definition:
      "equivalent to the product of five and two; one more than nine, or one less than eleven; 10",
  },
  {
    word: "tenth",
    signFile: "tenth",
    type: "Numerals",
    part: "",
    source: "",
    definition: "constituting number ten in a sequence; 10th",
  },
  {
    word: "third",
    signFile: "third",
    type: "Numerals",
    part: "",
    source: "",
    definition:
      "coming after the second and before all others in time or order; 3rd",
  },
  {
    word: "thirteen",
    signFile: "thirteen",
    type: "Numerals",
    part: "",
    source: "",
    definition:
      "equivalent to the sum of ten and three; one more than twelve, or one less than fourteen; 13",
  },
  {
    word: "thirteenth",
    signFile: "thirteenth",
    type: "Numerals",
    part: "",
    source: "",
    definition: "constituting number thirteen in a sequence; 13th",
  },
  {
    word: "thirty",
    signFile: "thirty",
    type: "Numerals",
    part: "",
    source: "",
    definition: "three times ten; 30",
  },
  {
    word: "thousand",
    signFile: "thousand",
    type: "Numerals",
    part: "",
    source: "",
    definition: "a unit of one thousand, equal to 10^3 or 1,000",
  },
  {
    word: "thousandth",
    signFile: "thousandth",
    type: "Numerals",
    part: "",
    source: "",
    definition: "constituting number one thousand in a sequence; 1000th",
  },
  {
    word: "three",
    signFile: "three",
    type: "Numerals",
    part: "",
    source: "",
    definition:
      "equivalent to the sum of two and one; one more than two, or one less than four; 3",
  },
  {
    word: "twelfth",
    signFile: "twelfth",
    type: "Numerals",
    part: "",
    source: "",
    definition: "constituting number twelve in a sequence; 12th",
  },
  {
    word: "twelve",
    signFile: "twelve",
    type: "Numerals",
    part: "",
    source: "",
    definition:
      "equivalent to the product of three and four; one more than eleven, or one less than thirteen; 12",
  },
  {
    word: "twentieth",
    signFile: "twentieth",
    type: "Numerals",
    part: "",
    source: "",
    definition: "constituting number twenty in a sequence; 20th",
  },
  {
    word: "twenty",
    signFile: "twenty",
    type: "Numerals",
    part: "",
    source: "",
    definition:
      "equivalent to the product of two and ten; one more than nineteen, or one less than twenty-one; 20",
  },
  {
    word: "two",
    signFile: "two",
    type: "Numerals",
    part: "",
    source: "",
    definition: "equivalent to the sum of one and one; one less than three; 2",
  },
  {
    word: "zero",
    signFile: "zero",
    type: "Numerals",
    part: "",
    source: "",
    definition: "no quantity or number; the figure 0",
  },
  {
    word: "again",
    signFile: "again",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "another time; once more",
  },
  {
    word: "all",
    signFile: "all",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "used to refer to the whole quantity or extent of a particular group or thing",
  },
  {
    word: "always",
    signFile: "always",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "at all times; on all occasions",
  },
  {
    word: "bad",
    signFile: "bad",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "of poor quality or a low standard; inferior or defective",
  },
  {
    word: "best",
    signFile: "best",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "of the most excellent or desirable type or quality",
  },
  {
    word: "better",
    signFile: "better",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "of a more excellent or effective type or quality",
  },
  {
    word: "big",
    signFile: "big",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "of considerable size, extent, or intensity",
  },
  {
    word: "different",
    signFile: "different",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "not the same as another or each other; unlike in nature, form, or quality",
  },
  {
    word: "easy",
    signFile: "easy",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "achieved without great effort; presenting few difficulties",
  },
  {
    word: "enough",
    signFile: "enough",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "as much or as many as required",
  },
  {
    word: "every",
    signFile: "every",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "used to refer to all the individual members of a set without exception",
  },
  {
    word: "evil",
    signFile: "evil",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "profoundly immoral and malevolent",
  },
  {
    word: "fantastic",
    signFile: "fantastic",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "extraordinarily good or attractive",
  },
  {
    word: "far",
    signFile: "far",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "at, to, or by a great distance (used to indicate the extent to which one thing is distant from another)",
  },
  {
    word: "fed up",
    signFile: "fed-up",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "bored, annoyed, or disappointed, especially by something that has continued for too long",
  },
  {
    word: "few",
    signFile: "few",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "a small number of",
  },
  {
    word: "first",
    signFile: "first",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "coming before all others in time or order; earliest",
  },
  {
    word: "full",
    signFile: "full",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "containing or holding as much or as many as possible; having no empty space",
  },
  {
    word: "funny",
    signFile: "funny",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "causing laughter or amusement; humorous",
  },
  {
    word: "good",
    signFile: "good",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "to be desired or approved of; having the required qualities; of a high standard",
  },
  {
    word: "half",
    signFile: "half",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "either of two equal or corresponding parts into which something is or can be divided",
  },
  {
    word: "happy",
    signFile: "happy",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "feeling or showing pleasure",
  },
  {
    word: "hard",
    signFile: "hard",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "requiring a great deal of endurance or effort; difficult or arduous to accomplish",
  },
  {
    word: "high",
    signFile: "high",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "of great vertical extent",
  },
  {
    word: "ill",
    signFile: "ill",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "not in good health; unwell",
  },
  {
    word: "interesting",
    signFile: "interesting",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "arousing curiosity or interest; holding or catching the attention",
  },
  {
    word: "last",
    signFile: "last",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "coming after all others in time or order; final",
  },
  {
    word: "little",
    signFile: "little",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "small in size, amount, or degree",
  },
  {
    word: "lots",
    signFile: "lots",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "a large number or amount; a great deal",
  },
  {
    word: "many",
    signFile: "many",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "a large number of",
  },
  {
    word: "medical",
    signFile: "medical",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "relating to the science of medicine, or to the treatment of illness and injuries",
  },
  {
    word: "more",
    signFile: "more",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "a greater or additional amount or degree of",
  },
  {
    word: "most",
    signFile: "most",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "the greatest amount or degree of",
  },
  {
    word: "new",
    signFile: "new",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "not existing before; made, introduced, or discovered recently or now for the first time",
  },
  {
    word: "old",
    signFile: "old",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "having lived for a long time; no longer young",
  },
  {
    word: "other",
    signFile: "other",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "used to refer to a person or thing that is different or distinct from one already mentioned or known",
  },
  {
    word: "painful",
    signFile: "painful",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "causing physical pain",
  },
  {
    word: "patient",
    signFile: "patient",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "able to accept or tolerate delays, problems, or suffering without becoming annoyed or anxious",
  },
  {
    word: "perfect",
    signFile: "perfect",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "having all the required or desirable elements, qualities, or characteristics; as good as it is possible to be",
  },
  {
    word: "plenty",
    signFile: "plenty",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "a large or sufficient amount or quantity; more than enough",
  },
  {
    word: "ready",
    signFile: "ready",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "prepared for immediate action or use",
  },
  {
    word: "real",
    signFile: "real",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "actually existing as a thing or occurring in fact; not imagined or supposed",
  },
  {
    word: "really",
    signFile: "really",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "in actual fact, as opposed to what is said or imagined to be true or possible",
  },
  {
    word: "right",
    signFile: "right",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "morally good, justified, or acceptable; correct or true",
  },
  {
    word: "same",
    signFile: "same",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "identical; not different",
  },
  {
    word: "small",
    signFile: "small",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "of a size that is less than normal or usual",
  },
  {
    word: "some",
    signFile: "some",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "an unspecified amount or number of",
  },
  {
    word: "still",
    signFile: "still",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "not moving or making a sound",
  },
  {
    word: "strict",
    signFile: "strict",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "demanding that rules concerning behavior are obeyed and observed",
  },
  {
    word: "strong",
    signFile: "strong",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "having the power to move heavy weights or perform other physically demanding tasks",
  },
  {
    word: "tired",
    signFile: "tired",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "in need of sleep or rest; weary",
  },
  {
    word: "top",
    signFile: "top",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "of the highest quality, amount, or degree",
  },
  {
    word: "true",
    signFile: "true",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "in accordance with fact or reality",
  },
  {
    word: "very",
    signFile: "very",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "used to emphasize an adjective or adverb, especially when it occurs as part of a single-word utterance",
  },
  {
    word: "well",
    signFile: "well",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "in a good or satisfactory way",
  },
  {
    word: "worse",
    signFile: "worse",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "of poorer quality or lower standard; less good or desirable",
  },
  {
    word: "wrong",
    signFile: "wrong",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition: "not correct or true; incorrect",
  },
  {
    word: "young",
    signFile: "young",
    type: "Adjectives and Adverbs",
    part: "",
    source: "",
    definition:
      "having lived or existed for only a short time; not fully grown or developed; of or characteristic of youth",
  },

  {
    word: "Belfast",
    signFile: "Belfast",
    type: "Places",
    part: "",
    source: "",
    definition:
      "the capital and largest city of Northern Ireland, on the eastern shore of Lough Neagh; pop. 272,600 (est. 2009).",
  },
  {
    word: "Birmingham",
    signFile: "Birmingham",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a city and metropolitan borough in the West Midlands of England; pop. 970,892 (est. 2009).",
  },
  {
    word: "Blackpool",
    signFile: "Blackpool",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a seaside resort on the Irish Sea coast of England, in Lancashire; pop. 139,720 (est. 2009).",
  },
  {
    word: "Brighton",
    signFile: "Brighton",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a seaside resort in East Sussex, in southern England; pop. 134,400 (est. 2009).",
  },
  {
    word: "China",
    signFile: "China",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a country in East Asia, the world's most populous country, with a population of over 1.4 billion.",
  },
  {
    word: "Denmark",
    signFile: "Denmark",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a country in northern Europe, the southernmost of the Scandinavian nations; pop. 5,475,791 (est. 2009); capital, Copenhagen; language, Danish (official).",
  },
  {
    word: "Dublin",
    signFile: "Dublin",
    type: "Places",
    part: "",
    source: "",
    definition:
      "the capital of the Republic of Ireland; pop. 1,122,800 (est. 2009).",
  },
  {
    word: "Edinburgh",
    signFile: "Edinburgh",
    type: "Places",
    part: "",
    source: "",
    definition:
      "the capital city of Scotland, on the southern shore of the Firth of Forth; pop. 437,500 (est. 2009).",
  },
  {
    word: "England",
    signFile: "England",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a country that is part of the United Kingdom, occupying most of the southern two-thirds of the island of Great Britain; pop. 49,138,831 (est. 2009); capital, London; language, English.",
  },
  {
    word: "Finland",
    signFile: "Finland",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a country in northern Europe, bounded by Sweden, Norway, and Russia; pop. 5,318,000; capital, Helsinki; language, Finnish and Swedish (both official).",
  },
  {
    word: "France",
    signFile: "France",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a country in western Europe, sharing borders with Belgium, Luxembourg, Germany, Switzerland, Italy, Spain, and Andorra; pop. 60,980,000; capital, Paris; official language, French.",
  },
  {
    word: "Gaelic",
    signFile: "Gaelic",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a member of the Celtic people of Scotland, or of Ireland; the language of the Gaels, which survives mainly in Scotland as Scottish Gaelic, and in Ireland as Irish.",
  },
  {
    word: "Germany",
    signFile: "Germany",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a country in central Europe, on the Baltic Sea in the north; pop. 82,422,000; capital, Berlin; language, German.",
  },
  {
    word: "Glasgow",
    signFile: "Glasgow",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a city in west central Scotland, the largest in the country; pop. 615,070 (est. 2009).",
  },
  {
    word: "Greece",
    signFile: "Greece",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a country in southeastern Europe, on the southern part of the Balkan Peninsula, bordered by the Ionian Sea on the west, the Mediterranean Sea on the south, and the Aegean Sea on the east; pop. 10,688,000; capital, Athens; official language, Greek.",
  },
  {
    word: "India",
    signFile: "India",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a country in southern Asia, occupying the greater part of the Indian subcontinent; pop. 1,166,079,217 (est. 2009); capital, New Delhi; official languages, Hindi and English.",
  },
  {
    word: "Ireland",
    signFile: "Ireland",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a country in northwestern Europe, occupying five-sixths of the island of Ireland; pop. 6,043,000; capital, Dublin; official languages, Irish (Gaelic) and English.",
  },
  {
    word: "Italy",
    signFile: "Italy",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a country in southern Europe, comprising a peninsula extending into the Mediterranean Sea, with the two largest islands in the Mediterranean, Sicily and Sardinia, and several smaller islands; pop. 59,619,290; capital, Rome; official language, Italian.",
  },
  {
    word: "Japan",
    signFile: "Japan",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a country in eastern Asia, occupying a group of islands off the eastern coast of Asia; pop. 127,880,000; capital, Tokyo; official language, Japanese.",
  },
  {
    word: "Leeds",
    signFile: "Leeds",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a city in West Yorkshire, in northern England; pop. 770,800 (est. 2009).",
  },
  {
    word: "Leicester",
    signFile: "Leicester",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a city in central England, the county town of Leicestershire; pop. 294,600 (est. 2009).",
  },
  {
    word: "Liverpool",
    signFile: "Liverpool",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a city and seaport in northwestern England, on the eastern side of the mouth of the Mersey River; pop. 435,500 (est. 2009).",
  },
  {
    word: "London",
    signFile: "London",
    type: "Places",
    part: "",
    source: "",
    definition:
      "the capital of the United Kingdom, in southeastern England on the Thames River; pop. 7,512,400.",
  },
  {
    word: "Manchester",
    signFile: "Manchester",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a city in northwestern England, the largest in the region; pop. 458,800 (est. 2009).",
  },
  {
    word: "Northern Ireland",
    signFile: "Northern-Ireland",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a constituent part of the United Kingdom, occupying the northeastern part of the island of Ireland; pop. 1,685,000; capital, Belfast; language, English (official), and Irish (official).",
  },
  {
    word: "Norway",
    signFile: "Norway",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a country in northern Europe, occupying the western part of the Scandinavian Peninsula; pop. 4,691,849; capital, Oslo; official language, Norwegian.",
  },
  {
    word: "Pakistan",
    signFile: "Pakistan",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a country in South Asia, the sixth most populous country in the world; pop. 177,000,000; capital, Islamabad; official languages, Urdu and English.",
  },
  {
    word: "Paris",
    signFile: "Paris",
    type: "Places",
    part: "",
    source: "",
    definition:
      "the capital of France, situated in the north-central part of the country; pop. 2,125,246. Paris was the largest city in the western world from the 16th century until the mid 19th century, when it was overtaken by London.",
  },
  {
    word: "Portsmouth",
    signFile: "Portsmouth",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a port and naval base on the English Channel, in southeastern England, on the southern coast of the Isle of Wight; pop. 187,056.",
  },
  {
    word: "Portugal",
    signFile: "Portugal",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a country in southwestern Europe, on the Iberian Peninsula, bordered by Spain and the Atlantic Ocean; pop. 10,707,000; capital, Lisbon; official language, Portuguese.",
  },
  {
    word: "Rome",
    signFile: "Rome",
    type: "Places",
    part: "",
    source: "",
    definition:
      "the capital of Italy, situated in the central part of the country, on the Tiber River; pop. 2,491,000. According to tradition, the ancient city was founded by Romulus (after whom it is named) in 753 bc.",
  },
  {
    word: "Russia",
    signFile: "Russia",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a country in northern Eurasia, the largest in the world; pop. 142,500,000; capital, Moscow; official language, Russian.",
  },
  {
    word: "Scotland",
    signFile: "Scotland",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a country in northern Great Britain and the United Kingdom; pop. 5,062,011; capital, Edinburgh; official languages, English and Scottish Gaelic.",
  },
  {
    word: "Spain",
    signFile: "Spain",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a country in southwestern Europe, occupying the greater part of the Iberian Peninsula; pop. 46,063,511; capital, Madrid; language, Spanish.",
  },
  {
    word: "Sweden",
    signFile: "Sweden",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a country in northern Europe, occupying the eastern part of the Scandinavian Peninsula; pop. 9,031,088; capital, Stockholm; language, Swedish.",
  },
  {
    word: "Usa",
    signFile: "Usa",
    type: "Places",
    part: "",
    source: "",
    definition: "United States of America",
  },
  {
    word: "Wales",
    signFile: "Wales",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a principality and constituent country of the United Kingdom, bordered by England to the east, the Bristol Channel to the south, and the Irish Sea to the west and north; pop. 3,063,456 (2011); capital, Cardiff; Welsh name, Cymru; Welsh language, Cymraeg.",
  },
  {
    word: "Welsh",
    signFile: "Welsh",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a native or inhabitant of Wales, or a person of Welsh descent; the Celtic language of Wales.",
  },
  {
    word: "area",
    signFile: "area",
    type: "Places",
    part: "",
    source: "",
    definition: "a region or part of a town, a country, or the world.",
  },
  {
    word: "bank",
    signFile: "bank",
    type: "Places",
    part: "",
    source: "",
    definition: "the land alongside or sloping down to a river or lake.",
  },
  {
    word: "café",
    signFile: "café",
    type: "Places",
    part: "",
    source: "",
    definition: "a small restaurant selling light meals and drinks.",
  },
  {
    word: "club",
    signFile: "club",
    type: "Places",
    part: "",
    source: "",
    definition:
      "an association or organization dedicated to a particular interest or activity.",
  },
  {
    word: "english",
    signFile: "english",
    type: "Places",
    part: "",
    source: "",
    definition: "relating to England or its people or language.",
  },
  {
    word: "farm",
    signFile: "farm",
    type: "Places",
    part: "",
    source: "",
    definition:
      "an area of land and its buildings used for growing crops and rearing animals, typically under the control of one owner or manager.",
  },
  {
    word: "home",
    signFile: "home",
    type: "Places",
    part: "",
    source: "",
    definition:
      "the place where one lives permanently, especially as a member of a family or household.",
  },
  {
    word: "hospital",
    signFile: "hospital",
    type: "Places",
    part: "",
    source: "",
    definition:
      "an institution providing medical and surgical treatment and nursing care for sick or injured people.",
  },
  {
    word: "pub",
    signFile: "pub",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a building with a bar and one or more public rooms licensed for the sale and consumption of alcohol, typically open late into the night.",
  },
  {
    word: "room",
    signFile: "room",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a part or division of a building enclosed by walls, floor, and ceiling.",
  },
  {
    word: "shop",
    signFile: "shop",
    type: "Places",
    part: "",
    source: "",
    definition:
      "a building or part of a building where goods or services are sold; a store.",
  },
  {
    word: "world",
    signFile: "world",
    type: "Places",
    part: "",
    source: "",
    definition:
      "the earth, together with all of its countries, peoples, and natural features.",
  },
];
